import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Image } from 'semantic-ui-react'
import styled, { css } from 'styled-components'

import { getFileImages } from 'context/project-file/utils'
import { Colors } from 'styles/app/system'
import { ProjectFile } from 'types/project-file'
import { responsiveText } from 'views/utils'

const SelectFileCard = ({
  disabled,
  file,
  onSelect,
  selected,
}: {
  disabled?: boolean
  file: ProjectFile
  onSelect: (id: string) => void
  selected?: boolean
}) => {
  return (
    <SelectCard
      disabled={disabled}
      selected={selected}
      onClick={() => onSelect(file.id)}
    >
      <div className="flex">
        <Image
          size="mini"
          src={`${process.env.GATSBY_CDN_ROOT}/${encodeURIComponent(
            getFileImages(file.key, file.numPages)[0],
          )}`}
        />
        <p className="no-margin">{responsiveText(file.name, 35)}</p>
        {disabled && (
          <p>
            <i>File selected</i>
          </p>
        )}
      </div>
      <div className="circle-check">
        <FontAwesomeIcon icon={['fal', 'check']} color="white" />
      </div>
    </SelectCard>
  )
}

export default SelectFileCard

const SelectCard = styled.div<{ disabled?: boolean; selected?: boolean }>`
  align-items: center;
  border: ${(props) =>
    props.selected
      ? `1px solid ${Colors.gray1000}`
      : `1px solid ${Colors.gray200}`};
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  flex-basis: 100%;
  justify-content: space-between;
  margin-bottom: 16px;
  padding: 10px 14px;

  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
      cursor: default;
    `}

  div {
    gap: 16px;
  }

  img {
    object-fit: cover;
    height: 100% !important;
  }

  div.circle-check {
    align-items: center;
    background: ${Colors.grayDark};
    border-radius: 50%;
    display: ${(props) => (props.selected ? 'flex' : 'none')};
    height: 24px;
    justify-content: center;
    margin: 0;
    width: 24px;

    svg {
      font-size: 12px;
    }
  }

  ${(props) =>
    !props.selected &&
    !props.disabled &&
    css`
      :hover {
        border: 1px solid ${Colors.gray1000};
        box-shadow: 0px 0px 15px rgba(120, 113, 108, 0.15);
      }
    `}
`
